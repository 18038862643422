<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Edit area">
                <b-form-checkbox v-model="othersVisible">Show other areas</b-form-checkbox>
                <hr/>
                <b-form-group>
                    <label>Area name:</label>
                    <b-form-input v-model="areaToEdit.name"/>
                </b-form-group>

                <ColorPicker v-model="areaToEdit.color"/>

                <div id="map" style="width: 100%; height: 500px"></div>
                <hr/>
                <b-button variant="warning" class="mr-1" @click="saveArea">
                    <feather-icon
                        icon="SaveIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Save area</span>
                </b-button>
                <b-button variant="danger" class="mr-1" @click="removeArea">
                    <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                    />
                    <span class="align-middle">Remove area</span>
                </b-button>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BFormGroup, BFormInput, BOverlay, BFormCheckbox} from 'bootstrap-vue'
    import mapboxgl from 'mapbox-gl'
    import MapboxDraw from '@mapbox/mapbox-gl-draw'
    import 'mapbox-gl/dist/mapbox-gl.css'
    import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
    import ColorPicker from '@/views/components/ColorPicker'

    export default {
        components: {
            ColorPicker,
            BCard,
            BOverlay,
            BFormInput,
            BButton,
            BFormGroup,
            BFormCheckbox
        },
        data() {
            return {
                dataLoaded: false,
                areaToEdit: {},
                map: null,
                drawController: null,
                othersVisible: true,
                areaLayers: []
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/area')
                loadPromise.then(function(response) {

                    thisIns.areaToEdit = response.data.find(area => area.id === thisIns.$route.params.id)
                    if (!thisIns.areaToEdit) {
                        thisIns.$printError('Area to edit not found')
                        return
                    }

                    thisIns.removeAreaLayers()
                    response.data.filter(area => area.id !== thisIns.$route.params.id).forEach(area => thisIns.addAreaLayer(area))

                    thisIns.drawController.deleteAll()
                    thisIns.drawController.add(
                        {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Polygon',
                                'coordinates': [thisIns.areaToEdit.polygon]
                            }
                        }
                    )

                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveArea() {
                if (!this.polygonSelected()) {
                    this.$printError('Polygon not selected')
                    return
                }

                const saveObject = {
                    name: this.areaToEdit.name,
                    color: this.areaToEdit.color,
                    polygon: this.drawController.getAll().features[0].geometry.coordinates[0]
                }

                const thisIns = this
                const loadPromise = this.$http.put(`/api/management/v1/area/${  this.$route.params.id}`, saveObject)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Area saved')
                    thisIns.$router.go(-1)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            removeArea() {
                const thisIns = this
                const removePromise = this.$http.delete(`/api/management/v1/area/${  this.$route.params.id}`)
                removePromise.then(function() {
                    thisIns.$printSuccess('Area removed')
                    thisIns.$router.go(-1)
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })
            },
            attachMap() {
                mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: this.$store.state.app.default_map_view.center,
                    zoom: this.$store.state.app.default_map_view.zoom
                })

                this.drawController = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: {
                        trash: true
                    },
                    defaultMode: 'simple_select'
                })

                this.map.addControl(this.drawController)

                const thisIns = this
                this.map.on('draw.modechange', function() {
                    if (thisIns.drawController.getAll().features.length === 0) {
                        thisIns.drawController.changeMode('draw_polygon')
                    }
                })

                this.map.on('draw.delete', function() {
                    thisIns.drawController.changeMode('draw_polygon')
                })

                return new Promise((resolve) => {
                    this.map.on('load', function() {
                        resolve()
                    })
                })
            },
            addAreaLayer(area) {
                this.map.addSource(area.id, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Polygon',
                            'coordinates': [area.polygon]
                        }
                    }
                })

                this.map.addLayer({
                    'id': `${area.id  }_fill`,
                    'type': 'fill',
                    'source': area.id, // reference the data source
                    'layout': {},
                    'paint': {
                        'fill-color': area.color, // blue color fill
                        'fill-opacity': 0.5
                    }
                }, 'gl-draw-polygon-fill-inactive.cold')

                this.map.addLayer({
                    'id': `${area.id  }_outline`,
                    'type': 'line',
                    'source': area.id,
                    'layout': {},
                    'paint': {
                        'line-color': '#000',
                        'line-width': 1
                    }
                }, 'gl-draw-polygon-fill-inactive.cold')

                this.areaLayers.push(area.id)
            },
            removeAreaLayers() {
                this.areaLayers.forEach(layer => {
                    this.map.removeLayer(`${layer  }_fill`)
                    this.map.removeLayer(`${layer  }_outline`)
                    this.map.removeSource(layer)
                })
                
                this.areaLayers = []
            },
            polygonSelected() {
                if (!this.drawController) {
                    return false
                } else if (this.drawController.getAll().features.length !== 1) {
                    return false
                } else if (this.drawController.getAll().features.[0].geometry.coordinates.length !== 1) {
                    return false
                } else if (this.drawController.getAll().features.[0].geometry.coordinates[0].length < 3) {
                    return false
                } else {
                    return true
                }
            },
            setOtherAreasVisibility(visible) {
                this.areaLayers.forEach(layer => {
                    this.map.setLayoutProperty(`${layer  }_fill`, 'visibility', (visible) ? 'visible' : 'none')
                    this.map.setLayoutProperty(`${layer  }_outline`, 'visibility', (visible) ? 'visible' : 'none')
                })
            }
        },
        watch: {
            othersVisible(val) {
                this.setOtherAreasVisibility(val)
            }
        },
        mounted() {
            const thisIns = this
            Promise.all([this.attachMap()]).finally(function() {
                thisIns.loadData()
            })
        }
    }
</script>